exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-3-sum-closest-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/3sum-closest/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-3-sum-closest-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-anatomy-of-postmanpatdev-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/anatomy_of_postmanpatdev/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-anatomy-of-postmanpatdev-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-convert-sorted-list-to-bs-tree-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/convert-sorted-list-to-bs-tree/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-convert-sorted-list-to-bs-tree-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-image-overlap-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/image-overlap/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-image-overlap-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-number-of-dice-rolls-to-target-sum-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/number-of-dice-rolls-to-target-sum/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-number-of-dice-rolls-to-target-sum-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-template-post-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/template_post/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-template-post-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-two-sum-iv-input-is-bst-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2023/two-sum-iv-input-is-bst/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-two-sum-iv-input-is-bst-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-non-decreasing-subsequence-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2024/non-decreasing-subsequence/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-non-decreasing-subsequence-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-salesforce-shadow-dom-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2024/salesforce-shadow-dom/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-salesforce-shadow-dom-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-test-automation-salesforce-part-1-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/postmanpatdev/postmanpatdev/src/posts/2024/test-automation-salesforce-part-1/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-test-automation-salesforce-part-1-index-mdx" */)
}

